import { createRouter, createWebHistory } from "vue-router"
import { usePlatformStore } from "@/stores/Platform"
import Relationships from "@/router/relationships"
import Organizations from "@/router/organizations"
import Reservations from "@/router/reservations"
import StarterPacks from "@/router/starterpacks"
import { useUserStore } from "@/stores/User"
import Landing from "@/views/LandingNew.vue"
import Reporting from "@/router/reporting"
import SignOut from "@/views/SignOut.vue"
import Locations from "@/router/location"
import Platform from "@/router/platform"
import SignUp from "@/views/SignUp.vue"
import SignIn from "@/views/SignIn.vue"
import Verify from "@/views/Verify.vue"
import Explore from "@/router/explore"
import Profile from "@/router/profile"
import Council from "@/router/council"
import Search from "@/router/search"
import Events from "@/router/events"
import Groups from "@/router/groups"
import Submit from "@/router/submit"

import Kinks from "@/router/kinks"
import { storeToRefs } from "pinia"

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: {
      title: "The future of BDSM, Fetish, and Kink Community - Submit",
      noAuth: true
    }
  },
  {
    path: '/landing',
    name: 'Landing.New',
    component: Landing,
    meta: {
      title: "The future of BDSM, Fetish, and Kink Community - Submit",
      noAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
    meta: {
      noAuth: true
    }
  },
  {
    path: '/transparency',
    name: 'Transparency',
    component: () => import('@/views/Transparency/Index.vue'),
    meta: {
      noAuth: true
    }
  },
  {
    path: '/transparency/:year/monthly/:month',
    name: 'Transparency.MonthlyReport',
    component: () => import('@/views/Transparency/MonthlyReport.vue'),
    meta: {
      noAuth: true
    }
  },
  {
    path: '/transparency/:year/quarterly/:quarter',
    name: 'Transparency.QuarterlyReport',
    component: () => import('@/views/Transparency/QuarterlyReport.vue'),
    meta: {
      title: "Quarterly Report - Submit",
      noAuth: true
    }
  },
  {
    path: '/transparency/:year/annual',
    name: 'Transparency.AnnualReport',
    component: () => import('@/views/Transparency/AnnualReport.vue'),
    meta: {
      title: "Annual Report - Submit",
      noAuth: true
    }
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
    meta: {
      title: "Sign In - Submit",
      noAuth: true
    }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    meta: {
      title: "Join Submit!",
      noAuth: true
    }
  },
  {
    path: '/verify',
    name: 'Verify',
    component: Verify,
    meta: {
      title: "Verify Email - Submit",
      noAuth: true
    }
  },
  {
    path: '/signup/early',
    name: 'SignUp.Early',
    component: () => import('@/views/Early.vue'),
    meta: {
      title: "Sign Up - Submit",
      noAuth: true
    }
  },
  {
    path: '/signout',
    name: 'SignOut',
    component: SignOut,
    meta: {
      title: "Sign Out - Submit"
    }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('@/views/Reset.vue'),
    meta: {
      title: "Reset Password - Submit",
      noAuth: true
    }
  },
  {
    path: '/backer',
    name: 'Backer',
    component: () => import('@/views/Backer.vue'),
    meta: {
      noAuth: true
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue'),
    meta: {
      noAuth: true
    }
  },
  {
    path: '/appeal',
    name: 'Appeal',
    component: () => import('@/views/Appeal/Index.vue'),
    meta: {
      title: "Appeal - Submit",
      noAuth: true
    }
  },
  {
    path: '/notfound',
    name: 'NotFound.Page',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      title: "Not Found (404) - Submit"
    }
  },
  {
    path: '/404',
    name: 'NotFound.404',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      title: "Not Found (404) - Submit"
    }
  },
  ...Platform,
  ...Events,
  ...Organizations,
  ...Reporting,
  ...Groups,
  ...Submit,
  ...Explore,
  ...Kinks,
  ...Council,
  ...Reservations,
  ...Relationships,
  ...Search,
  ...Profile,
  ...Locations,
  ...StarterPacks,
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
    meta: {
      title: `Not Found (404) - Submit`
    }
  }
]

let isFirstNavigation = true

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      if (to.hash === "#top") {
        return { top: 0, behavior: "smooth" }
      }
      return { el: to.hash, behavior: "smooth" }
    }
    return { top: 0, left: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  // Set navigationFromApp meta flag
  // It's an internal navigation if:
  // 1. It's not the first navigation
  // 2. We have a from.name (meaning we're navigating from another route)
  to.meta.navigationFromApp = !isFirstNavigation && !!from.name

  // After first navigation, set flag to false
  isFirstNavigation = false

  // check if from.name and to.name have "Profile." in them, if so, set subloading instead of loading
  if (from.name) {
    const fromName = from.name as string
    const toName = to.name as string
    if (fromName.includes("Profile.") && toName.includes("Profile.")) {
      if (to.meta.subSubLoading) {
        usePlatformStore().setSubSubLoading(true)
      } else {
        usePlatformStore().setSubLoading(true)
      }
    } else if (fromName.includes("Settings.") && toName.includes("Settings.")) {
      usePlatformStore().setSubLoading(true)
    } else if (fromName.includes("Groups.") && toName.includes("Groups.")) {
      usePlatformStore().setSubLoading(true)
    } else if (fromName.includes("Group.") && toName.includes("Group.")) {
      usePlatformStore().setSubLoading(true)
    } else if (fromName.includes("Submit.") && toName.includes("Submit.")) {
      usePlatformStore().setSubLoading(true)
    } else if (fromName.includes("Messages.") && toName.includes("Messages.")) {
      usePlatformStore().setSubLoading(true)
    } else if (fromName.includes("Kinks.") && toName.includes("Kinks.")) {
      usePlatformStore().setSubLoading(true)
    } else if (fromName.includes("Reservations.") && toName.includes("Reservations.")) {
      usePlatformStore().setSubLoading(true)
    } else if (fromName.includes("Explore.") && toName.includes("Explore.")) {
      usePlatformStore().setSubLoading(true)
    } else if (fromName.includes("Relationships.") && toName.includes("Relationships.")) {
      usePlatformStore().setSubLoading(true)
    } else if (fromName.includes("Events.") && toName.includes("Events.")) {
      usePlatformStore().setSubLoading(true)
    } else if (fromName.includes("Event.") && toName.includes("Event.")) {
      usePlatformStore().setSubLoading(true)
    } else if (fromName.includes("Home.") && toName.includes("Home.")) {
      usePlatformStore().setSubLoading(true)
    } else {
      usePlatformStore().setLoading(true)
    }
  } else {
    usePlatformStore().setLoading(true)
  }
  const userStore = useUserStore()
  const { signedIn, roles } = storeToRefs(userStore)
  if (to.matched.some((record) => record.meta.noAuth)) {
    if (signedIn.value) {
      if (["/signin", "/signup", "/"].includes(to.path)) {
        next({ name: "Home.Following" })
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    if (signedIn.value) {
      if (to.matched.some((record) => record.meta.admin)) {
        if (roles.value.includes("admin")) {
          next()
        } else {
          next({ name: "NotFound.Page" })
        }
      } else {
        next()
      }
    } else {
      try {
        await userStore.initUser()
        next()
      } catch (err) {
        const shouldRedirect = !to.fullPath.includes('signin') && !to.fullPath.includes('signout') && !to.fullPath.includes('signup')
        next({
          name: "SignIn",
          query: shouldRedirect ? { redirect: to.fullPath } : undefined
        })
      }
    }
  }
})

router.afterEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title as string
  }
  usePlatformStore().setLoading(false)
  usePlatformStore().setSubLoading(false)
  usePlatformStore().setSubSubLoading(false)
})

export default router
